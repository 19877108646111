
@mixin dtb-two-stop-gradient($fromColor, $toColor) {
	background-color: $toColor; /* Fallback */
	background-image: -webkit-linear-gradient(top, $fromColor 0%, $toColor 100%); /* Chrome 10+, Saf5.1+, iOS 5+ */
	background-image:    -moz-linear-gradient(top, $fromColor 0%, $toColor 100%); /* FF3.6 */
	background-image:     -ms-linear-gradient(top, $fromColor 0%, $toColor 100%); /* IE10 */
	background-image:      -o-linear-gradient(top, $fromColor 0%, $toColor 100%); /* Opera 11.10+ */
	background-image:         linear-gradient(to bottom, $fromColor 0%, $toColor 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{nth( $fromColor, 1 )}', EndColorStr='#{nth( $toColor, 1 )}');
}

@mixin dtb-radial-gradient ($fromColor, $toColor ) {
	background: $toColor; /* Fallback */
	background:     -ms-radial-gradient(center, ellipse farthest-corner, $fromColor 0%, $toColor 100%); /* IE10 Consumer Preview */ 
	background:    -moz-radial-gradient(center, ellipse farthest-corner, $fromColor 0%, $toColor 100%); /* Firefox */ 
	background:      -o-radial-gradient(center, ellipse farthest-corner, $fromColor 0%, $toColor 100%); /* Opera */ 
	background: -webkit-gradient(radial, center center, 0, center center, 497, color-stop(0, $fromColor), color-stop(1, $toColor)); /* Webkit (Safari/Chrome 10) */ 
	background: -webkit-radial-gradient(center, ellipse farthest-corner, $fromColor 0%, $toColor 100%); /* Webkit (Chrome 11+) */ 
	background: radial-gradient(ellipse farthest-corner at center, $fromColor 0%, $toColor 100%); /* W3C Markup, IE10 Release Preview */ 
}


@mixin dtb-fixed-collection {
	// Fixed positioning feature
	&.fixed {
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -75px;
		border-radius: 0;

		&.two-column {
			margin-left: -150px;
		}

		&.three-column {
			margin-left: -225px;
		}

		&.four-column {
			margin-left: -300px;
		}
	}

	// Multi-column layout feature
	-webkit-column-gap: 8px;
	   -moz-column-gap: 8px;
	    -ms-column-gap: 8px;
	     -o-column-gap: 8px;
	column-gap: 8px;

	> * {
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
	}

	&.two-column {
		width: 300px;
		padding-bottom: 1px;

		-webkit-column-count: 2;
		   -moz-column-count: 2;
		    -ms-column-count: 2;
		     -o-column-count: 2;
		column-count: 2;
	}

	&.three-column {
		width: 450px;
		padding-bottom: 1px;

		-webkit-column-count: 3;
		   -moz-column-count: 3;
		    -ms-column-count: 3;
		     -o-column-count: 3;
		column-count: 3;
	}

	&.four-column {
		width: 600px;
		padding-bottom: 1px;

		-webkit-column-count: 4;
		   -moz-column-count: 4;
		    -ms-column-count: 4;
		     -o-column-count: 4;
		column-count: 4;
	}
}
