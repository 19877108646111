
@import "select2bootstrap";

@import "responsiveTables";

.padding-top-xs { padding-top: 5px; }

.padding-top-s { padding-top: 10px; }

.padding-top-m { padding-top: 15px; }

.padding-top-l { padding-top: 20px; }

.padding-top-xl { padding-top: 25px; }

.margin-top-xs { margin-top: 5px; }

.margin-top-s { margin-top: 10px; }

.margin-top-m { margin-top: 15px; }

.margin-top-l { margin-top: 20px; }

.margin-top-xl { margin-top: 25px; }

.margin-right-s { margin-right: 10px; }

.margin-right-m { margin-right: 15px; }

.margin-right-l { margin-right: 20px; }

.margin-right-xl { margin-top: 25px; }

.margin-left-s { margin-left: 10px; }

.margin-left-m { margin-left: 15px; }

.margin-left-l { margin-left: 20px; }

.margin-left-xl { margin-top: 25px; }

.display-inline {
    display: inline;
}
.text-right {
    text-align: right;
}

.no-uldots {
    list-style: none;
}

.no-margin {
    margin: 0 !important;
}
$darkgreen: #00A65A; 
.skin-green .main-header {

  .navbar {
    background-color: $darkgreen;
  }
  .logo {
    background-color: $darkgreen;
  }
  li.user-header {
    background-color: $darkgreen;
  }
}
tbody  td {
  text-align:center;
}
.imageDocument{
  border-radius: 30px;
  margin-bottom: 10px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 10px;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
td > * {
      vertical-align : middle;
    }
    .required::before{
      content: '* ';
      color: red;
    }
.custom-file-input::-webkit-file-upload-button {
      visibility: hidden;
    }

    .custom-file-input::before {
      content: 'Subir imagen';
      display: inline-block;
      background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
      border: 1px solid #999;
      border-radius: 3px;
      padding: 5px 8px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      cursor: pointer;
      text-shadow: 1px 1px #fff;
      font-weight: 700;
      font-size: 10pt;
    }

    .custom-file-input:hover::before {
      border-color: black;
    }

    .custom-file-input:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }
    .colorRed{
      color:red;
    }
    .colorBlue{
      color:blue;
    }

    #loading-bar .bar {
          background: #33ba3a;
          height: 3px;
        }


    @media (max-width: 767px){
                .skin-black .main-header>.logo {
                  background-color: #fff !important;

                }

              }
              @media (min-width: 768px){
              .modal-dialog {
                width:970px;

              }
            }
            .modal { overflow: auto !important; }
            @media screen and (max-width:520px) {
              ul li {clear:left;}
            }


#userTable th{
  text-align: center;
}
@media print {
   .noprint{
      display: none !important;
   }
}