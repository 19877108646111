// Breakpoints
$bp-maggie: 15em; 
$bp-lisa: 30em;
$bp-bart: 48em;
$bp-marge: 62em;
$bp-homer: 75em;
$colorTable:  rgb(61, 153, 112);

.t3b-table-responsive {
  width: 100%;
  margin-bottom: 1.5em;

  @media (min-width: $bp-bart) {
    font-size: .9em; 
  }

  @media (min-width: $bp-marge) {
    font-size: 1em; 
  }

  thead {
    // Accessibly hide <thead> on narrow viewports
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px; 
    width: 1px; 
    overflow: hidden;

    @media (min-width: $bp-bart) {
      // Unhide <thead> on wide viewports
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;
    }

    th {
      background-color: $colorTable;
      border: 1px solid $colorTable;
      font-weight: normal;
      text-align: center;
      color: white;

      &:first-of-type {
        text-align: left; 
      }
    }
  }

  // Set these items to display: block for narrow viewports
  tbody,
  tr,
  th,
  td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }

  tr {   
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-row; 
    }
  }

  th,
  td {
    padding: .5em;
    vertical-align: middle;

    @media (min-width: $bp-lisa) {
      padding: .75em .5em; 
    }

    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-cell;
      padding: .5em;
    }

    @media (min-width: $bp-marge) {
      padding: .75em .5em; 
    }

    @media (min-width: $bp-homer) {
      padding: .75em; 
    }
  }

  caption {
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: bold;
    text-align: center;

    @media (min-width: $bp-bart) {
      font-size: 1.5em;
    }
  }

  tfoot {
    font-size: .8em;
    font-style: italic;

    @media (min-width: $bp-marge) {
      font-size: .9em;
    }
  }

  tbody {
    @media (min-width: $bp-bart) {
      // Undo display: block 
      display: table-row-group; 
    }

    tr {
      margin-bottom: 1em;
      border: 2px solid $colorTable;

      @media (min-width: $bp-bart) {
        // Undo display: block 
        display: table-row;
        border-width: 1px;
      }

      &:last-of-type {
        margin-bottom: 0; 
      }

      &:nth-of-type(even) {
        @media (min-width: $bp-bart) {
          background-color: rgba(94,93,82,.1);
        }
      }
    }

    th[scope="row"] {
      background-color: $colorTable;
      color: white;

      @media (min-width: $bp-bart) {
        background-color: transparent;
        color: rgba(94,93,82,1);
        text-align: left;
      }
    }

    td {
      text-align: right;

      @media (min-width: $bp-lisa) {
        border-bottom: 1px solid  $colorTable;
      }

      @media (min-width: $bp-bart) {
        text-align: center; 
      }
    }

    td[data-type=currency] {
      text-align: right; 
    }

    td[data-title]:before {
      content: attr(data-title);
      float: left;
      font-size: 1em;
      color: rgba(94,93,82,.9);

      @media (min-width: $bp-lisa) {
        font-size: .9em; 
      }

      @media (min-width: $bp-bart) {
        // Don’t show data-title labels 
        content: none; 
      }
    } 
  }
}
.t3b-responsive-img{
  object-fit: cover;
  height: 100px;
  width: 100px;
}
