
@import 'mixins.scss';
@import 'common.scss';

ul.dt-button-collection.dropdown-menu {
	display: block;
	z-index: 2002;

	@include dtb-fixed-collection();
}

div.dt-button-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2001;
}

@media screen and (max-width: 767px) {
	div.dt-buttons {
		float: none;
		width: 100%;
		text-align: center;
		margin-bottom: 0.5em;

		a.btn {
			float: none;
		}
	}
}
